<template>
  <div class="content-box-padding">
    <img
      src="~assets/image/about_us/资质荣誉1.png"
      alt="资质荣誉1"
      class="img-center"
      style="margin-bottom: 28px; width: 80%"
    />
    <div class="img-title">相关荣誉证书</div>
    <img
      src="~assets/image/about_us/资质荣誉2.png"
      alt="资质荣誉2"
      class="img-center"
      style="margin-bottom: 28px"
    />
    <div class="img-title">相关资质证书</div>
    <img
      src="~assets/image/about_us/资质荣誉3.png"
      alt="资质荣誉3"
      class="img-center"
      style="margin-bottom: 28px; width: 90%"
    />
    <div class="img-title">相关软著专利</div>
  </div>
</template>
